import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { getClassessAsyncThunk } from "../../redux/pagesSlices/classesSlice";
import { handleModel } from "../../redux/layoutSlices/modelSlice";
import { setClass } from "../../redux/pagesSlices/authSlice";
import spaceAvatar from "../../images/stripe-2.png";
import plusBlack from "../../images/plus-black.svg";
import { parseUrlFromModel } from "../../helpers/asset";
import { Button } from "react-bootstrap";
import add_icon from "../../images/add.svg";
import { IoMdArrowDropdown } from "react-icons/io";
import Classes from "../../images/Classes.svg";

// Custom Toggle Component
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

// Custom Menu Component with Search
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">{children}</ul>
      </div>
    );
  }
);

function SwitchSpaces() {
  const d = useDispatch();
  const classes = useSelector((s) => s.classes.Classes);
  const selectedSpace = useSelector((s) => s.auth.class);
  console.log("🚀 ~ SwitchSpaces ~ selectedSpace:", selectedSpace);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectClass = (item) => {
    selectClass(item); 
    setIsOpen(false); 
  };

  // useEffect(() => {
  //   d(getClassessAsyncThunk());
  // }, [d]);

  const loadMore = () => {
    d(getClassessAsyncThunk({ page: classes.page + 1 }));
  };

  const selectClass = (item) => {
    d(setClass(item));
    setSelectedClass(item);
    console.log("class selected", item);
  };

  useEffect(() => {
    if (!selectedSpace && classes.results && classes.results.length > 0) {
      setSelectedClass(classes.results[0]);
      d(setClass(classes.results[0]));
    }
  }, [classes.results, d]);

  // const handleCreat = () => {
  //   d(handleModel({ model: "CreatSpace", state: true }));
  // };

  return (
    // <Dropdown className="switch-spaces">
    //   <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
    //     <svg
    //       width="24"
    //       height="24"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M8 16C8.55 16 9.02083 15.8042 9.4125 15.4125C9.80417 15.0208 10 14.55 10 14C10 13.45 9.80417 12.9792 9.4125 12.5875C9.02083 12.1958 8.55 12 8 12C7.45 12 6.97917 12.1958 6.5875 12.5875C6.19583 12.9792 6 13.45 6 14C6 14.55 6.19583 15.0208 6.5875 15.4125C6.97917 15.8042 7.45 16 8 16ZM16 16C16.55 16 17.0208 15.8042 17.4125 15.4125C17.8042 15.0208 18 14.55 18 14C18 13.45 17.8042 12.9792 17.4125 12.5875C17.0208 12.1958 16.55 12 16 12C15.45 12 14.9792 12.1958 14.5875 12.5875C14.1958 12.9792 14 13.45 14 14C14 14.55 14.1958 15.0208 14.5875 15.4125C14.9792 15.8042 15.45 16 16 16ZM12 10C12.55 10 13.0208 9.80417 13.4125 9.4125C13.8042 9.02083 14 8.55 14 8C14 7.45 13.8042 6.97917 13.4125 6.5875C13.0208 6.19583 12.55 6 12 6C11.45 6 10.9792 6.19583 10.5875 6.5875C10.1958 6.97917 10 7.45 10 8C10 8.55 10.1958 9.02083 10.5875 9.4125C10.9792 9.80417 11.45 10 12 10ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
    //         fill="#2D2F31"
    //       />
    //     </svg>
    //     {selectedSpace ? selectedSpace.name : "Select Space"}
    //   </Dropdown.Toggle>

    //   <Dropdown.Menu as={CustomMenu} className="switch-spaces-dropdown">
    //     <div className="items-list">
    //       {classes &&
    //       classes.results &&
    //       Array.isArray(classes.results) &&
    //       classes.results.length > 0 ? (
    //         classes.results.map((item, index) => (
    //           <Dropdown.Item
    //             onClick={() => {
    //               selectClass(item);
    //             }}
    //             key={index}
    //             href="#"
    //           >
    //             <img src={parseUrlFromModel(item?.image) || require("../../images/logo.png")} alt="avatar" />
    //             {item.name}
    //           </Dropdown.Item>
    //         ))
    //       ) : (
    //         <Dropdown.Item href="#">No Space Found</Dropdown.Item>
    //       )}
    //       {classes &&
    //       classes.totalPages > 1 &&
    //       classes.page < classes.totalPages ? (
    //         <Dropdown.Item onClick={loadMore} href="#">
    //           Load More
    //         </Dropdown.Item>
    //       ) : null}
    //       {
    //                 classes && classes.totalPages > 1 && classes.page < classes.totalPages ?
    //                     <Dropdown.Item onClick={loadMore} href="#">Load More</Dropdown.Item>
    //                     : null
    //             }
    //             <Dropdown.Item href="#">Load More</Dropdown.Item>
    //     </div>
    //     <div className="create-space-btn">
    //       <a href="#" onClick={handleCreat}>
    //         <div className="logo">
    //           <img src={plusBlack} alt="plus-icon" />
    //         </div>
    //         Create Space{" "}
    //       </a>
    //     </div>
    //   </Dropdown.Menu>
    // </Dropdown>
    <>
      <div className="section-2">
        <div className="classes-icon"></div>
        <div className="dropdown-sec">
          <Dropdown show={isOpen} onToggle={toggleDropdown}>
            <Dropdown.Toggle
              onClick={toggleDropdown}
              id="dropdown-basic"
              className="create-btn"
            >
              <img src={Classes} alt="Classes_Icon" />
              {selectedSpace ? selectedSpace.name : "Select Space"}
              <div className="dropdown-icon">
                <IoMdArrowDropdown />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="menuItems">
                <div className="dropdown-heading">
                  <img
                    src={
                      parseUrlFromModel(selectedSpace?.image) ||
                      require("../../images/logo.png")
                    }
                    alt="avatar"
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50px",
                    }}
                  />
                  <strong>
                    {" "}
                    {selectedSpace ? selectedSpace.name : "Select Space"}
                  </strong>
                </div>
                <div className="mainItems">
                  {classes &&
                  classes.results &&
                  Array.isArray(classes.results) &&
                  classes.results.length > 0 ? (
                    classes.results.map((item, index) => (
                      <div
                        className="item"
                        key={index}
                        onClick={() => handleSelectClass(item)}
                      >
                        <img
                          src={
                            parseUrlFromModel(item?.image) ||
                            require("../../images/logo.png")
                          }
                          alt="avatar"
                          style={{
                            width: "36px",
                            height: "36px",
                            borderRadius: "50px",
                          }}
                        />
                        <p>{item.name}</p>
                      </div>
                    ))
                  ) : (
                    <p>No classes available</p>
                  )}
                </div>

                {/* <div className="createSpace" onClick={handleCreat}>
                  <Button variant="primary" >
                    <img src={add_icon} alt="addIcon" />
                  </Button>
                  <p>Create Space</p>
                </div> */}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export default SwitchSpaces;
