import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { getContentsAsyncThunk } from "../../../redux/pagesSlices/contentSlice";
import PdfCard from "./cards/PdfCard";
// import ProviderErrorLoadingScroll from "../../ProviderErrorLoadingScroll";
import { Form } from "react-bootstrap";
import { getContentsAsyncThunk } from "../../redux/pagesSlices/contentSlice";
import ProviderErrorLoadingScroll from "../ProviderErrorLoadingScroll";

export default function PdfsList({ _key }) {
  const d = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getContent(searchTerm);
      // Send Axios request here
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  useEffect(() => {
    if (_key === "pdf") getContent();
  }, [_key]);
  const getContent = () => {
    d(
      getContentsAsyncThunk({
        mimeType: "application/pdf",
        sortBy: "updatedAt:desc",
        page: 1,
        ...(searchTerm && { name: searchTerm }),
      })
    );
  };
  return (
    <div>
      <Form.Control
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search..."
        className="mb-3"
      />
      <div className="row" id="getContentsAsyncThunk_pdfs_parent">
        <ProviderErrorLoadingScroll
          emtpyMessage="Pdfs will show here!"
          reducer={"contents"}
          action={"getContentsAsyncThunk"}
          dataKey={"pdfs"}
          loadingIndicator={"Loadingdata"}
          Component={PdfCard}
          //  Parent={Patent}
          InfiniteScroll_props={{
            scrollableTarget: "getContentsAsyncThunk_pdfs_parent",
          }}
          loadMore={(e) =>
            d(
              getContentsAsyncThunk({
                ...e,
                mimeType: "application/pdf",
                sortBy: "updatedAt:desc",
                ...(searchTerm && { name: searchTerm }),
              })
            )
          }
          asyncThunk={getContentsAsyncThunk}
        />
      </div>
    </div>
  );
}
