import React from 'react'
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';

export default function SkeletonLoading() {
  return (
    <div>
      <div className="row">
        <div className="col-12 mb-4">
          <Skeleton variant="rectangular" height={75} animation="wave" style={{ borderRadius: 8, padding: '15px' }} />
        </div>
        <div className="col-12 mb-4">
          <Skeleton variant="rectangular" height={75} animation="wave" style={{ borderRadius: 8, padding: '15px' }} />
        </div>
        {/* <div className="col-12 mb-4">
          <Skeleton variant="rectangular" height={75} animation="wave" style={{ borderRadius: 8, padding: '15px' }} />
        </div> */}
      </div>
    </div>
  )
}



