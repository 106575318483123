import { APIurls } from "../constants.js";
import apiService from ".";
import multipart from "./multipart.js";

export const ApiRequests = {
  //user Authentication
  login: async (data) => await apiService.post(APIurls.login, data),
  logout: async (data) => await apiService.post(APIurls.logout, data),
  register: async (data) => await apiService.post(APIurls.register, data),
  authenticate: async () => await apiService.get(APIurls.authenticate),
  refreshTokens: async (data) =>
    await apiService.post(APIurls.refreshTokens, data),
  revokeToken: async (data) => await apiService.post(APIurls.revokeToken, data),

  // Class crud
  createClass: async (data) => await apiService.post(APIurls.classes, data),
  getClasss: async (params) =>
    await apiService.get(APIurls.classes, { params }),
  getClass: async (id) => await apiService.get(APIurls.classes + "/" + id),
  updateClass: async ({ data, id }) =>
    await apiService.patch(APIurls.classes + "/" + id, data),
  deleteClass: async (id) =>
    await apiService.delete(APIurls.classes + "/" + id),

  getProfile: async (token) =>
    await apiService.get(APIurls.profile, {
      headers: { ...(token ? { Authorization: "Bearer " + token } : {}) },
    }),
  getTopFiveRank: async (params) => await apiService.get(APIurls.topFiveRank, { params }),
  getHighlightedCounts: async (params) => await apiService.get(APIurls.highlightedCounts, { params }),
  getTopClassTrophies: async (params) => await apiService.get(APIurls.topClassTrophies, { params }),
  // organization
  createOrganization: async (data) =>
    await apiService.post(APIurls.organizations, data),
  deleteOrganization: async (id) =>
    await apiService.delete(APIurls.organizations + "/" + id),
  // deleteOrganization: async ({ id, data }) => await apiService.patch(APIurls.organizations + "/" + id, data),
  getOrganizations: async (params) =>
    await apiService.get(APIurls.organizations, { params }),
  getOrganization: async (id) =>
    await apiService.get(APIurls.organizations + "/" + id + "/name"),
  getOrganizationsByMail: async (params) =>
    await apiService.get(APIurls.bymail, { params }),
  getOrganizationsMembership: async (params) =>
    await apiService.get(APIurls.membership, { params }),
  getOrganizationMembership: async (id) =>
    await apiService.get(APIurls.membership + `/${id}`),

  // People crud
  createOwner: async (data) => await apiService.post(APIurls.members, data),
  createOwnerInvite: async (data) =>
    await apiService.post(APIurls.inviteUser, data),
  createUserExport: async (data) =>
    await apiService.post(APIurls.userExport, data),
  createUserImport: async (data) =>
    await multipart.post(APIurls.userImport, data),
  createUserRole: async (data) => await apiService.post(APIurls.userRole, data),

  getOwners: async (params) =>
    await apiService.get(APIurls.members, { params }),
  getOwner: async (user_id) =>
    await apiService.get(APIurls.members + "/" + user_id),
  updateOwner: async ({ data, id }) =>
    await apiService.patch(APIurls.members + "/" + id, data),
  deleteOwner: async (id) =>
    await apiService.delete(APIurls.members + "/" + id),
  confirmAssignRolesToOwner: async (id) =>
    await apiService.get(APIurls.members + "/assign-org/" + id),

  // invitations
  createInvitation: async (data) =>
    await apiService.post(APIurls.invites, data),
  getInvitations: async (params) =>
    await apiService.get(APIurls.invites, { params }),
  getInvitation: async (user_id) =>
    await apiService.get(APIurls.invites + "/" + user_id),
  updateInvitation: async ({ data, id }) =>
    await apiService.patch(APIurls.invites + "/" + id, data),
  deleteInvitation: async (id) =>
    await apiService.delete(APIurls.invites + "/" + id),

  // Group crud
  createGroup: async (data) => await apiService.post(APIurls.groups, data),
  getGroups: async (params) => await apiService.get(APIurls.groups, { params }),
  getGroup: async (id) => await apiService.get(APIurls.groups + "/" + id),
  updateGroup: async ({ data, id }) =>
    await apiService.patch(APIurls.groups + "/" + id, data),
  deleteGroup: async (id) => await apiService.delete(APIurls.groups + "/" + id),

  // Addresses crud
  createAddresses: async (data) =>
    await apiService.post(APIurls.addresses, data),
  getaAdressess: async (params) =>
    await apiService.get(APIurls.addresses, { params }),
  getAddresses: async (id) =>
    await apiService.get(APIurls.addresses + "/" + id),
  updateAddresses: async ({ data, id }) =>
    await apiService.patch(APIurls.addresses + "/" + id, data),
  deleteAddresses: async (id) =>
    await apiService.delete(APIurls.addresses + "/" + id),

  // Content crud
  createContent: async ({ data, options }) =>
    await multipart.post(APIurls.userContents, data, options),
  getUserContents: async (params) =>
    await apiService.get(APIurls.userContents, { params }),
  getContents: async (params) =>
    await apiService.get(APIurls.contents, { params }),
  getContent: async (id) => await apiService.get(APIurls.contents + "/" + id),
  updateContent: async ({ data, id }) =>
    await apiService.patch(APIurls.userContents + "/" + id, data),
  deleteContent: async (id) =>
    await apiService.delete(APIurls.userContents + "/" + id),
};
