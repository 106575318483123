import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { getContentsAsyncThunk } from "../../../redux/pagesSlices/contentSlice";
import ImageCard from "./cards/ImageCard";
// import ProviderErrorLoadingScroll from "../../ProviderErrorLoadingScroll";
import { useLayoutEffect } from "react";
import { Form } from "react-bootstrap";
import ProviderErrorLoadingScroll from "../ProviderErrorLoadingScroll";
import { getContentsAsyncThunk } from "../../redux/pagesSlices/contentSlice";

function ImagesList({ _key }) {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getContent(searchTerm);
      // Send Axios request here
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  const d = useDispatch();
  useEffect(() => {
    if (_key === "image") getContent();
  }, [_key]);
  const getContent = () => {
    d(
      getContentsAsyncThunk({
        mimeType: "image",
        sortBy: "updatedAt:desc",
        page: 1,
        limit: 100,
        ...(searchTerm && { name: searchTerm }),
      })
    );
  };

  return (
    <>
      <Form.Control
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search..."
        className="mb-3"
      />
      <div id="getContentsAsyncThunk_images_parent">
        <ProviderErrorLoadingScroll
          emtpyMessage="Images will show here!"
          reducer={"contents"}
          action={"getContentsAsyncThunk"}
          dataKey={"images"}
          loadingIndicator={"Loadingdata"}
          Component={ImageCard}
          //  Parent={Patent}
          InfiniteScroll_props={{
            scrollableTarget: "getContentsAsyncThunk_images_parent",
          }}
          loadMore={(e) =>
            d(
              getContentsAsyncThunk({
                ...e,
                mimeType: "image",
                sortBy: "updatedAt:desc",
                ...(searchTerm && { name: searchTerm }),
              })
            )
          }
          asyncThunk={getContentsAsyncThunk}
        />
      </div>
    </>
  );
}
export default React.memo(ImagesList);
