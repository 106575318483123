import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getUsersAsyncThunk } from "./userSlice";

// Start Class Slices
///////////////////////////////////////////////////

export const getClassessAsyncThunk = createAsyncThunk(
  "Class/getClassessAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getClasss(params);
    return response?.data;
  })
);

export const getClassesAsyncThunk = createAsyncThunk(
  "Class/getClassesAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getClass(id);
    return response?.data;
  })
);

export const createClassesAsyncThunk = createAsyncThunk(
  "Class/createClassesAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.createClass(data);
    if (response.status == 204) {
      toast.success("Class Create Successfully!");
    }
    if (callBack) callBack();
    dispatch(getClassessAsyncThunk({}));
    return response?.data;
  })
);

export const updateClassesAsyncThunk = createAsyncThunk(
  "Class/updateClassesAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.updateClass({ id, data });
    if (response.status == 204) {
      toast.success("Class Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(getClassessAsyncThunk({}));
    return response?.data;
  })
);

export const deleteClassesAsyncThunk = createAsyncThunk(
  "Class/deleteClassesAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteClass(id);
    if (response.status == 204) {
      toast.success("Class Deleted Successfully!");
      let params = {};
      let state = getState().listings;
      if (state.search) params.name = state.search;
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(getClassessAsyncThunk({ ...params, populate: "user_id" }));
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

export const classesAssignAsyncThunk = createAsyncThunk(
  "Class/classesAssignAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.assignClass(data);
    if (response.status == 200) {
      toast.success("Class Assign Successfully!");
    }
    if (callBack) callBack();
    dispatch(getUsersAsyncThunk({ populate: "user_id", role: "User" }));
    return response?.data;
  })
);



export const getStudentsOfAssignClassAsyncThunk = createAsyncThunk(
  "Class/getStudentsOfAssignClassAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getStudentsOfAssignClass(id);
    return response?.data;
  })
);




export const getAssignStudentsInCourseAsyncThunk = createAsyncThunk(
  "Class/getAssignStudentsInCourseAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getAssignStudentsInCourse(params);
    return response?.data;
  })
);


export const createAssignStudentsInCourseAsyncThunk = createAsyncThunk(
  "Class/createAssignStudentsInCourseAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.assignStudentsInCourse(data);
    if (response.status == 200) {
      toast.success("Assign Student to Course Successfully!");
    }
    if (callBack) callBack();
    dispatch(getAssignStudentsInCourseAsyncThunk({}));
    return response?.data;
  })
);


///////////////////////////////////////////////////

const initialState = {
  //news states
  Classes: {
    page: 0,
    users: [],
    totalPages: 1,
  },
  assignStudentsInCourse:{
    page: 0,
    users: [],
    totalPages: 1,
  },

  studentsOfAssignClass: [],
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  order: "asce",
};

const classSlice = createSlice({
  name: "Classes",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getClassessAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.Classes = {
            page: 0,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getClassessAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.Classes = {
            ...action.payload,
            users: state?.Classes?.results.concat(action?.payload?.results),
          };
        } else {
          state.Classes = action.payload;
        }
      })


      

      .addCase(getAssignStudentsInCourseAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.assignStudentsInCourse = {
            page: 0,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getAssignStudentsInCourseAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.assignStudentsInCourse = {
            ...action.payload,
            users: state?.assignStudentsInCourse?.results.concat(action?.payload?.results),
          };
        } else {
          state.assignStudentsInCourse = action.payload;
        }
      })



      .addCase(getClassesAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results),
          };
        } else {
          state.story = action.payload;
        }
      })

      .addCase(getStudentsOfAssignClassAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.studentsOfAssignClass = {
            ...action.payload,
            results: state?.studentsOfAssignClass?.results.concat(action?.payload?.results),
          };
        } else {
          state.studentsOfAssignClass = action.payload;
        }
      })


      
      .addCase(deleteClassesAsyncThunk.fulfilled, (state, action) => {})

      .addCase(classesAssignAsyncThunk.fulfilled, (state, action) => {})
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getClassessAsyncThunk,
            getClassesAsyncThunk,
            deleteClassesAsyncThunk,
            createClassesAsyncThunk,
            classesAssignAsyncThunk,
            getAssignStudentsInCourseAsyncThunk,
            getStudentsOfAssignClassAsyncThunk,
            createAssignStudentsInCourseAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default classSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  classSlice.actions;
