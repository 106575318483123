import React from "react";
import { Button } from "react-bootstrap";
import {
  BiCheckbox,
  BiCheckboxChecked,
  BiSelection,
  BiSelectMultiple,
} from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
// import { parseUrlFromModel } from "../../../../helpers/asset";
// import { handleModel } from "../../../../redux/layoutSlices/modelSlice";
import { parseUrlFromModel } from "../../../helpers/asset";
import { handleModel } from "../../../redux/layoutSlices/modelSlice";

function AudioCard(item) {
  const modelsArgs = useSelector(
    (state) => state.model?.modelArgs?.contentModel
  );
  const d = useDispatch();
  const handleImages = () => {
    if (modelsArgs?.audios && modelsArgs?.audios?.length) {
      if (modelsArgs?.audios?.some((k) => k.id === item.id)) {
        return modelsArgs.audios.filter((k) => k.id !== item.id);
      } else {
        return [...modelsArgs?.audios, item];
      }
    }
    return [item];
  };
  const handleClick = () => {
    if (modelsArgs?.type === "audio" || modelsArgs?.type === "audios") {
      d(
        handleModel({
          model: "contentModel",
          state: true,
          args: {
            ...modelsArgs,
            ...(modelsArgs?.type === "audio"
              ? { audio: item }
              : { audios: handleImages() }),
          },
        })
      );
    }
  };
  return (
    <div
      onClick={handleClick}
      className={`col-lg-6`}
      // ${modelsArgs?.type === "audios" ? (modelsArgs?.audios && modelsArgs?.audios?.some(k => k.id === item.id) && "selected-image") : modelsArgs?.type === "audio" && (modelsArgs?.audio?.id === item.id) && "selected-image"}
    >
      <div
        style={{ display: "flex", marginTop: "10px" }}
        className="audio-selection"
      >
        <div style={{ marginTop: "8px", color: "#333" }}>
          {modelsArgs?.type === "audios" ? (
            modelsArgs?.audios &&
            modelsArgs?.audios?.some((k) => k.id === item.id) ? (
              <BiCheckboxChecked size={38} />
            ) : (
              <BiCheckbox size={38} />
            )
          ) : modelsArgs?.type === "audio" &&
            modelsArgs?.audio?.id === item.id ? (
            <BiCheckboxChecked size={38} />
          ) : (
            <BiCheckbox size={38} />
          )}
        </div>
        <audio onClick={handleClick} controls>
          <source
            onClick={handleClick}
            src={
              parseUrlFromModel(item) ??
              require("../../../images/maths2.png")
            }
            type="audio/ogg"
          />
        </audio>
      </div>
    </div>
  );
}
export default React.memo(AudioCard);
