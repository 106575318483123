import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import userIcon from "../images/user.svg";
// import familyIcon from "../images/familyIcon.png";
// import stafIcon from "../../images/noData/stafIcon.png";
// import classIcon from "../../images/noData/classIcon.png";
// import interactionIcon from "../../images/noData/interactionIcon.png";
// import labelIcon from "../../images/noData/labelIcon.png";
// import notesIcon from "../../images/noData/notesIcon.png";

AOS.init();

export default function NoDataFound({
  NotFoundImage,
  NoDataHeading,
  NoDataTitle,
  customHeight,
}) {
  return (
    // <div className="access-denied-page">
    //   <div className="access-page-wrapper">
    //     <div className="lock-img">
    //       {/* <img src={require("../images/notfound.png")} alt="#"/> */}
    //       {
    //         dataKey === "users" ? (
    //           <>
    //             <img src={userIcon} />
    //             <h2>Not Students found!</h2>
    //             <p>We couldn't find any data matching in this criteria.</p>
    //           </>
    //         ) : dataKey === "contacts" ? (
    //           <>
    //             <img src={userIcon} />
    //             <h2>Not Guardians found!</h2>
    //             <p>We couldn't find any data matching in this criteria.</p>
    //           </>
    //         ) : dataKey === "Classes" ? (
    //           <>
    //             <img src={classIcon} />
    //             <h2>No Classes found!</h2>
    //             <p>We couldn't find any data matching in this criteria.</p>
    //           </>
    //         ) : dataKey === "families" ? (
    //           <>
    //             <img src={familyIcon} />
    //             <h2>Not Family found!</h2>
    //             <p>We couldn't find any data matching in this criteria.</p>
    //           </>
    //         ) : dataKey === "staffs" ? (
    //           <>
    //             <img src={stafIcon} />
    //             <h2>Not Staff found!</h2>
    //             <p>We couldn't find any data matching in this criteria.</p>
    //           </>
    //         ) : dataKey === "interactions" ? (
    //           <>
    //             <img src={interactionIcon} />
    //             <h2>Not Interactions found!</h2>
    //             <p>We couldn't find any data matching in this criteria.</p>
    //           </>
    //         ) : dataKey === "notes" ? (
    //           <>
    //             <img src={notesIcon} />
    //             <h2>Not Notes found!</h2>
    //             <p>We couldn't find any data matching in this criteria.</p>
    //           </>
    //         ) : dataKey === "labels" ? (
    //           <>
    //             <img src={labelIcon} />
    //             <h2>Not Labels found!</h2>
    //             <p>We couldn't find any data matching in this criteria.</p>
    //           </>
    //         ) : (
    //           <>
    //             <img src={userIcon} />
    //             <h2>Not Students found!</h2>
    //             <p>We couldn't find any data matching in this criteria.</p>
    //           </>
    //         )
    //         // staffs
    //       }
    //       {/* <FaRegUser /> */}
    //       {/* <h2>Not Students found!</h2>
    //       <p>We couldn't find any data matching in this criteria.</p> */}
    //       {/* <button>Go Back</button> */}
    //     </div>
    //     {/* <div className='footer-wrapper'>
    //          <div className='img-inner'>
    //              <img src={cross} alt="" />
    //              <p className='my-2'>This page is not exist </p>
    //          </div>
    //      </div> */}
    //   </div>
    // </div>
    <div className="access-denied-page" style={{ padding: "0px" }}>
      <div className="access-page-wrapper">
        <div className="lock-img">
          {NotFoundImage || <img src={userIcon} alt="#" />}
          <h2>{NoDataHeading || "NO DATA FOUND!"}</h2>
          <p>{NoDataTitle}</p>
        </div>
      </div>
    </div>
  );
}
