import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { parseUrlFromModel } from "../../../../helpers/asset";
// import { handleModel } from "../../../../redux/layoutSlices/modelSlice";
// import ImageLazyLoader from "../../../imageLoader/ImageLazyLoader";
import { Button } from "react-bootstrap";
import { handleModel } from "../../../redux/layoutSlices/modelSlice";
import { parseUrlFromModel } from "../../../helpers/asset";
import ImageLazyLoader from "../../imageLoader/ImageLazyLoader";

function ImageCard(item) {
  console.log("🚀 ~ ImageCard ~ item:", item)
  console.log("render ImageCard");
  const modelsArgs = useSelector(
    (state) => state.model?.modelArgs?.contentModel
  );

  const d = useDispatch();

  const handleImages = () => {
    if (modelsArgs?.images && modelsArgs?.images?.length) {
      if (modelsArgs?.images?.some((k) => k.id === item.id)) {
        return modelsArgs.images.filter((k) => k.id !== item.id);
      } else {
        return [...modelsArgs?.images, item];
      }
    }
    return [item];
  };

  const args = {
    ...modelsArgs,
    ...(modelsArgs?.type === "image"
      ? { image: item }
      : { images: handleImages() }),
  };
  const closeModel = () => {
    if (modelsArgs?.callBack) modelsArgs.callBack(args[modelsArgs.type]);
    d(handleModel({ model: "contentModel", state: false }));
  };

  const [isSelected, setIsSelected] = useState(false);

  // const selectImageHandler = (item) => {
  //   if (modelsArgs?.type === "images") {
  //     setIsSelected(true); // Toggle isSelected state on click
  //     console.log("item img", item)
  //   }
  // };

  return (
    <div
      className={`col-lg-4 ${
        modelsArgs?.type === "images"
          ? modelsArgs?.images &&
            modelsArgs?.images?.some((k) => k.id === item.id) &&
            "selected-image"
          : modelsArgs?.type === "image" &&
            modelsArgs?.image?.id === item.id &&
            "selected-image"
      }`}
      onClick={() => {
        if (modelsArgs?.type === "image" || modelsArgs?.type === "images") {
          d(
            handleModel({
              model: "contentModel",
              state: true,
              args,
            })
          );
          if (modelsArgs?.callBack) modelsArgs.callBack(args[modelsArgs.type]);
          closeModel();
        }
      }}
    >
      <div>
        <div>
          <ImageLazyLoader src={parseUrlFromModel(item)} />
        </div>
      </div>
    </div>
  );
}
export default React.memo(ImageCard);
