export const parseUrlFromModelAvatar = e => "https://content.ilmiyacdn.com/assets/AVATARS/" + encodeURIComponent(e);
export const parseUrlFromModel = (data) => {
    if (!data || !data.bucket || !data.name) {
      return null; // Return null if the required properties are not present
    }
  
    // Construct the link using the bucket and name properties
    const link = `https://content.ilmiyacdn.com/${data.name}`;
  
    return link;
  };
export const parseUrlFromModelPatches = e => "https://content.ilmiyacdn.com/assets/PATCHES WITH BACKGOUND/1 Planetary Patch/" + encodeURIComponent(e);
